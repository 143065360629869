import { useEffect, useState } from "react";
import "./App.css";

import Char from "./components/Characters";

import Cartao from "./components/CharCard";

import "./components/CharCard/css/styles.css";

import Button from "./components/SwitchPage/button";

import "./components/SwitchPage/estilos/btn.css";

function App() {
  const [characterList, SetcharacterList] = useState("");
  const [next, Setnext] = useState(1);

  useEffect(() => {
    fetch(`https://rickandmortyapi.com/api/character?page=${next}`)
      .then((response) => response.json())
      .then((response) => SetcharacterList(response))
      .catch((err) => console.log(err));
  }, [next]);

  const NextPage = () => {
    Setnext(next + 1);
  };

  const Previous = () => {
    if (next > 0) {
      Setnext(next - 1);
    }
  };

  console.log(characterList.results);
  return (
    <div className="App">
      <Button func1={NextPage} func2={Previous} />
      <header className="App-header">
        <Char teste={characterList.results} />
      </header>
    </div>
  );
}

export default App;
