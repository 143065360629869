import { FaRegHandPointRight, FaRegHandPointLeft } from "react-icons/fa";

const Button = ({ func1, func2 }) => {
  return (
    <main className="m">
      <button className="next" onClick={func1}>
        <div>Proxima pagina</div>
        <FaRegHandPointRight />
      </button>

      <button className="previous" onClick={func2}>
        <div>Pagina Anterior</div>
        <FaRegHandPointLeft />
      </button>
    </main>
  );
};

export default Button;
