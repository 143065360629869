const Cartao = ({ personagem }) => {
  return (
    <>
      {personagem.status === "Alive" ? (
        <div className="green">
          <div>{personagem.name}</div>
          <img src={personagem.image} alt="img" />
        </div>
      ) : (
        <div className="red">
          <div>{personagem.name}</div>
          <img src={personagem.image} alt="img" />
        </div>
      )}
    </>
  );
};

export default Cartao;
