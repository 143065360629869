import Cartao from "../CharCard";

const Char = ({ teste }) => {
  return (
    <>
      <h1>Meus personagens</h1>
      {teste?.map((elemento) => (
        <Cartao key={elemento.id} personagem={elemento} />
      ))}
    </>
  );
};

export default Char;
